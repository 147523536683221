.navbar {
  background-color: #ffffff;
}
.navbar-light .navbar-nav .nav-link,
.dropdown-item {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
}

.nav-logo {
  max-height: 42px;
}
