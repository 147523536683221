:root {
  --navy-color: #0b294b;
  --royal-blue: #4a5fe2;
  --blue-bg: #0065a9;
  --light-bg: #f8f9fa;
  --text-color: #525f7f;
  --footer-text: rgba(42, 53, 66, 0.5);
}
html {
  font-size: 1rem;
  height: -webkit-fill-available;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  font-family: "Montserrat", sans-serif;
  color: var(--text-color);
  position: relative;
  top: 58px;
}

h1,
h2,
h3,
h4,
.card-title,
.text-dark {
  color: var(--navy-color);
}

@media only screen and (min-width: 1200px) {
  html {
    font-size: 1.2rem;
  }
}

@media only screen and (min-width: 992px) {
  h1 {
    font-size: 3rem;
    font-weight: 400;
  }
  h2 {
    font-size: 2.5rem;
    font-weight: 400;
  }
  h3 {
    font-size: 2rem;
    font-weight: 400;
  }
  .nav-button {
    height: 30px !important;
    padding-top: 0;
    padding-bottom: 0;
  }
  .hero,
  .particles {
    height: 750px;
  }
}

@media only screen and (max-width: 991px) {
  h1 {
    font-size: 2.5rem;
    font-weight: 400;
  }
  h2 {
    font-size: 2rem;
    font-weight: 400;
  }
  h3 {
    font-size: 1.5rem;
    font-weight: 400;
  }
  .dropdown-menu {
    border: none;
  }
  .hero,
  .particles {
    height: 750px !important;
  }
}

@media only screen and (max-width: 575px) {
  h1 {
    font-size: 2rem;
    font-weight: 500;
  }
  h2 {
    font-size: 1.5rem;
    font-weight: 500;
  }
  h3 {
    font-size: 1.25rem;
    font-weight: 500;
  }
  .vh-full {
    height: calc(100vh - 58px);
    max-height: 850px;
  }
}

.particles {
  position: absolute;
  background-color: var(--navy-color);
  z-index: 1;
}

.hero h1 {
  z-index: 2;
}

.blue {
  font-size: 1rem;
  background-color: var(--blue-bg);
  color: #ffffff;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.icon {
  max-width: 64px;
  max-height: 64px;
  margin: auto;
  margin-right: 10px;
}
.inline-icon {
  display: inline;
  margin: auto;
}
.card-title {
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
}
.card-subtitle {
  font-size: 1rem;
}

.card-text {
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  color: var(--text-color);
}

.carousel-indicators li {
  background-color: var(--royal-blue);
  position: relative;
  top: 50px;
  width: 10px;
  height: 10px;
}

.btn-main,
.btn-second,
.btn-transparent,
.card-link {
  font-size: 0.85rem;
  text-transform: uppercase;
  color: var(--royal-blue);
  font-weight: 600;
  height: 44px;
  letter-spacing: 1px;
}

.link {
  color: var(--royal-blue);
}

.card-link,
.link {
  background: none;
  border: none;
}
.card-link:focus,
.link:focus {
  outline: none;
}
.card-link:hover,
.link:hover {
  color: var(--blue-bg);
  text-decoration: none;
}

.btn-main {
  background-color: var(--royal-blue);
  color: #ffffff;
}
.btn-main:hover {
  color: #ffffff;
  background-color: #3e4fb2;
}

.btn-second {
  background-color: #ffffff;
  color: var(--navy-color);
  border: var(--navy-color) 1px solid;
}
.btn-second:hover {
  background-color: #ebebeb;
}

.btn-transparent {
  color: var(--light-bg);
  border: var(--light-bg) 1px solid;
}
.btn-transparent:hover {
  color: var(--light-bg);
  background-color: #1672af;
}

.blue-bg {
  background-color: var(--blue-bg);
  color: #ffffff;
}

.light-bg {
  background-color: var(--light-bg);
}

.navy-bg {
  background-color: var(--navy-color);
  color: #ffffff;
}

.casestudy-logo {
  max-width: 80px;
}

.text-dark {
  color: var(--text-color);
}

.footer-text {
  font-size: 0.8rem;
  line-height: 1rem;
}

.footer-text a {
  color: var(--text-color);
}
.footer-text a:hover {
  color: var(--footer-text);
  text-decoration: none;
}

.banner {
  background-image: url("/images/background.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
}

div p:last-child {
  margin-bottom: 0px;
}
.fab {
  max-height: 16px;
}

.illustration {
  max-height: 400px;
  max-width: 100%;
}
.dropdown-menu {
  left: auto;
  top: auto;
}
.dropdown-item:focus,
.dropdown-item:active {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #16181b;
}

.navbar-toggler:focus {
  outline: 2px auto var(--royal-blue);
}

.paging-item button {
  fill: var(--royal-blue) !important;
}

.paging-item button:focus {
  outline: none;
}

.greyscale {
  filter: grayscale(100%);
}

.particle-container {
  position: absolute;
  margin-bottom: -8px;
  top: 0;
  z-index: 1;
}

.marketing {
  padding-top: 5rem;
  padding-inline-start: 0;
}

.marketing li {
  margin: 1rem;
  list-style: none;
}

.marketing li i {
  margin-right: 10px;
  color: var(--royal-blue);
}

.lable {
  color: var(--navy-color);
  font-weight: 500;
  margin-bottom: 0.1rem;
  font-size: 0.9rem;
}

.toggle-expand {
  line-height: 1rem;
  font-size: 1.8rem;
  margin-top: 0.5rem;
}

.z-2 {
  z-index: 2;
}
